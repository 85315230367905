.edit-block {
  margin-bottom: 80px;

  @media(max-width: 1199px) {
    margin-bottom: 50px;
  }

  @media(max-width: 991px) {
    margin-bottom: 10px;
  }

  $root: &;

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    @media(max-width: 1199px) {
      margin: 0 -10px;
    }
  }

  &__item {
    width: 100%;
    max-width: calc(100% - 40px);
    margin: 0 20px 20px;
    border-radius: 20px;
    overflow: hidden;
    padding: 40px 40px;
    background: $dark;
    position: relative;
    font-size: 0;
    display: flex;
    flex-direction: column;
    box-shadow: 7px 7px 30px $shadow-primary;
    min-height: 200px;

    &_33 {
      max-width: calc(33.333% - 40px);

      @media(max-width: 1199px) {
        max-width: calc(50% - 20px);
      }

      @media(max-width: 991px) {
        max-width: calc(100% - 20px);
      }
    }

    &_50 {
      max-width: calc(50% - 40px);
    }

    &_100 {
      max-width: calc(100% - 40px);
    }

    @media(max-width: 1199px) {
      padding: 30px;
      margin: 0 10px 10px;
    }

    @media(max-width: 991px) {
      max-width: calc(100% - 20px);
      margin: 0 10px 20px 10px;
    }

    @media(max-width: 767px) {
      padding: 20px;
    }

    @media(max-width: 350px) {
      min-height: 160px;
    }

    &:nth-child(2n) {
      #{$root} {
        &__item-image {
          background: #cbcbd0;
        }
      }
    }
  }

  &__item-image {
    background: $dark;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__item-thumb {
    max-width: none;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 90% 50%;
  }

  &__item-title {
    font-weight: bold;
    font-size: 47px;
    color: #fff;
    margin-bottom: 43px;
    position: relative;

    @media(max-width: 1440px) {
      font-size: 40px;
      margin-bottom: 30px;
    }

    @media(max-width: 1199px) {
      font-size: 30px;
    }

    @media(max-width: 575px) {
      font-size: 25px;
    }
  }

  &__item-row {
    margin-top: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  &__item-link {
    background: rgba(255, 255, 255, 0.07);
    padding: 11px 52px 11px 20px;
    margin-right: 20px;
    position: relative;
    color: #FFF;
    font-weight: 600;
    font-size: 15px;

    @media(max-width: 1199px) {
      padding: 10px 50px 10px 20px;
    }

    @media(max-width: 575px) {
      font-size: 14px;
      padding: 10px 30px 10px 15px;
    }
  }

  &__item-ico {
    position: absolute;
    top: calc(50% - 6px);
    width: 12px;
    height: 12px;
    right: 20px;
    fill: #fff;
    transform: rotate(180deg);

    @media(max-width: 575px) {
      right: 10px;
    }
  }

  &__item-label {
    font-weight: bold;
    font-size: 18px;
    color: #fff;
    background: #FF7A5C;
    border-radius: 7px;
    padding: 9px 20px;

    @media(max-width: 575px) {
      padding: 8px 15px;
      font-size: 14px;
    }
  }
}
