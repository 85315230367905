.gallery {
  $root: &;

  &--page {
    width: 100%;

    #{$root} {}
  }

  &--home {
    border-radius: 20px;
    width: 100%;
    max-width: calc(50% - 40px);
    margin-left: 40px;
    background: linear-gradient(-45deg, #ff9b4a, #c837ab),
    linear-gradient(45deg, #df437e, #c837ab);

    @media (max-width: 1740px) {
      align-self: flex-start;
    }

    @media (max-width: 991px) {
      max-width: 100%;
      margin: 30px 0 0 0;
    }

    #{$root} {
      &__inner {
        background: #fff;
        margin: 2px;
        border-radius: 18px;
        padding: 48px 58px 58px;
        height: calc(100% - 4px);
        width: calc(100% - 4px);
        display: flex;
        flex-direction: column;

        @media (max-width: 1740px) {
          padding: 25px;
        }

        @media (max-width: 767px) {
          padding: 20px;
        }

        @media (max-width: 575px) {
          padding: 10px;
        }
      }
    }

    &__item {
      max-width: calc(33.333% - 20px);

      @media (min-width: 1441px) {
        &:nth-last-child(-n + 3) {
          margin-bottom: 0;
        }
      }

      @media (max-width: 1440px) {
        max-width: calc(50% - 20px);
        &:nth-last-child(-n + 2) {
          margin-bottom: 0;
        }
      }

      @media (max-width: 991px) {
        max-width: calc(33.333% - 20px);
        &:nth-last-child(-n + 3) {
          margin-bottom: 0;
        }
      }

      @media (max-width: 575px) {
        max-width: calc(50% - 20px);
        &:nth-last-child(-n + 2) {
          margin-bottom: 0;
        }
      }
    }

  }


  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: auto -10px;
    align-self: center;
    align-content: center;
    width: 100%;
  }

  &__item {
    width: 100%;
    max-width: calc(20% - 20px);
    margin: 0 10px 20px 10px;
    position: relative;
    cursor: pointer;
    border-radius: 7px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    &::after {
      content: "";
      display: block;
      padding-top: 100%;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $shadow-primary;
      opacity: 0;
      transition: opacity 0.3s ease;
      z-index: 1;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  &__thumb {
    position: absolute;
    top: 0;
    left: 0;
    max-width: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }

  &__elem {
    width: 100%;
    max-width: calc(20% - 20px);
    margin: 0 10px 20px 10px;
    position: relative;
    cursor: pointer;
    box-shadow: 4px 4px 10px rgba(65, 68, 92, 0.3);
    border-radius: 3px;

    @media (max-width: 991px) {
      max-width: calc(25% - 20px);
    }

    @media (max-width: 991px) {
      max-width: calc(33.333% - 20px);
    }

    @media (max-width: 767px) {
      max-width: calc(50% - 20px);
    }

    @media (max-width: 375px) {
      max-width: calc(100% - 20px);
    }
  }

  &__elem-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    display: block;
    margin-bottom: 15px;

    @media (max-width: 767px) {
      height: 220px;
      margin-bottom: 12px;
    }
  }

  &__elem-body {
    padding: 0 15px 20px;

    @media (max-width: 767px) {
      padding: 0 10px 10px;
    }
  }

  &__elem-title {
    color: $primary;
    font-size: 18px;
    font-weight: 500;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  &__elem-description {
    color: $dark;
    font-size: 15px;
    margin-top: 5px;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}
