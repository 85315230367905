* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

$base-font: 'Montserrat', sans-serif;
$base-font-size: 16px;
$base-color: #191919;
@import 'swiper/swiper.scss';
@import '@/scss/vars.scss';

body {
  font-family: $base-font;
  font-size: $base-font-size;
  min-width: 320px;
  line-height: 1.25;

  &.open-modal {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  transition: color .2s ease;
  &:hover, &:focus, &:active {
    text-decoration: none;
  }
}

input, textarea {
  background: none;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  font-family: $base-font;
}

button {
  background: none;
  border: none;
  appearance: none;

  font-family: $base-font;
  &:hover{
    cursor: pointer;
  }
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper {
  min-height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;

  $root: &;

  &--active {
    #{$root} {
      &__container {
        transform: translate(432px, 0);
      }
    }
  }

  &__container {
    transition: transform .3s ease;
  }
}

.container {
  width: 100%;
  max-width: 1740px;
  padding: 0 10px;

  &--main {
    padding-left: 60px;
    @media(max-width: 1599px) {
      padding-left: 90px;
    }
    @media(max-width: 1199px) {
      padding-left: 100px;
    }
    @media(max-width: 991px) {
      padding-left: 60px;
    }
    @media(max-width: 890px) {
      padding-left: 90px;
    }
    @media(max-width: 820px) {
      padding-left: 100px;
    }
    @media(max-width: 767px) {
      padding-left: 10px;
    }
  }

  @media(max-width: 1740px) {
    max-width: 1440px;
  }

  @media(max-width: 1440px) {
    max-width: 1200px;
  }

  @media(max-width: 1199px) {
    max-width: 991px;
  }

  @media(max-width: 991px) {
    max-width: 768px;
  }

  @media(max-width: 767px) {
    max-width: 100%;
  }

}

@mixin placeholder($color) {
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &::-webkit-input-placeholder {
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $color;
    opacity: 1;
  }
  &::-ms-input-placeholder {
    color: $color;
    opacity: 1;
  }
  &::placeholder {
    color: $color;
    opacity: 1;
  }
}

@import './components/gallery/index.scss';
@import './components/edit-block/index.scss';
